<template>
  <div id="login" class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630871487/loops_icon_n9ebrj.svg" alt="Loops Logo">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        <span v-if="showLoginForm">Sign in to your account</span>
        <span v-if="showForgotPassword">Reset your password</span>
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600 max-w">
          Or
          <router-link v-if="showLoginForm" to="/signup" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
           get started for free
          </router-link>
          <button  v-if="showForgotPassword" @click="togglePasswordReset" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
           login to your account
          </button>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <form v-if="showLoginForm" @submit.prevent>
          <div>
            <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
              Email address
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input v-model.trim="loginForm.email" id="email" type="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div class="mt-6">
            <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
              Password
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <input v-model.trim="loginForm.password" id="password" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <transition name="fade">
            <div v-if="errorMsg !== ''" class="bg-red-50 border-l-4 border-red-400 p-4 mt-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm leading-5 text-red-700">
                      {{ errorMsg }}
                    </p>
                  </div>
                </div>
            </div>
          </transition>

          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <button @click="login" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                <span v-if="!performingRequest">Sign in</span>
                <transition name="fade">
                    <div v-if="performingRequest" class="loading">
                        <p>Loading...</p>
                    </div>
                </transition>
              </button>
            </span>
          </div>

          <div class="mt-6 flex items-center justify-start">

            <div class="text-sm leading-5">
              <a href="#" @click="togglePasswordReset" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                Forgot your password?
              </a>
            </div>
          </div>

        </form>

        <form v-if="showForgotPassword" @submit.prevent>
            <div v-if="!passwordResetSuccess">
                <div class="mt-6">
                    <label for="reset" class="block text-sm font-medium leading-5 text-gray-700">
                        Email
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                      <input v-model.trim="passwordForm.email" type="text" required placeholder="you@email.com" id="reset" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                </div>
                <transition name="fade">
                  <div v-if="errorMsg !== ''" class="bg-red-50 border-l-4 border-red-400 p-4 mt-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
                          </svg>
                        </div>
                        <div class="ml-3">
                          <p class="text-sm leading-5 text-red-700">
                            {{ errorMsg }}
                          </p>
                        </div>
                      </div>
                  </div>
                </transition>
                <div class="mt-6">
                      <span class="block w-full rounded-md shadow-sm">
                        <button @click="resetPassword" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          <span v-if="!performingRequest">Request Reset Link</span>
                          <transition name="fade">
                              <div v-if="performingRequest" class="loading">
                                  <p>Loading...</p>
                              </div>
                          </transition>
                        </button>
                    </span>
                </div>
            </div>
            <div v-else>
              <transition name="fade">
                <div class="rounded-md bg-green-50 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm leading-5 font-medium text-green-800">
                          Email Sent
                        </h3>
                        <div class="mt-2 text-sm leading-5 text-green-700">
                          <p>
                            Check your email for a link to reset your password.
                          </p>
                        </div>
                        <div class="mt-4">
                          <div class="-mx-2 -my-1.5 flex">
                            <button @click="togglePasswordReset" class="px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
                              Back to login
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </transition>
            </div>
        </form>

      </div>

    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')
export default {
  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      passwordForm: {
        email: ''
      },
      showLoginForm: true,
      showForgotPassword: false,
      passwordResetSuccess: false,
      performingRequest: false,
      errorMsg: ''
    }
  },
  mounted () {
    window.analytics.page('Login')
  },
  computed: {
    ...mapState(['userProfile', 'currentUser'])
  },
  methods: {
    togglePasswordReset () {
      if (this.showForgotPassword) {
        this.showLoginForm = true
        this.showForgotPassword = false
        this.passwordResetSuccess = false
        this.passwordForm.email = this.loginForm.email
      } else {
        this.showLoginForm = false
        this.showForgotPassword = true
      }

      this.errorMsg = ''
    },
    async login () {
      try {
        if (this.loginForm.email.length && this.loginForm.password.length) {
          this.performingRequest = true
          await fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
            this.$store.commit('setCurrentUser', user.user)
            this.$store.dispatch('fetchUserProfile')
            // Track Sign Up
            window.analytics.track('User Signed In', {
              email: user.user.email
            })
            this.$router.push('/app')
          }).catch(err => {
            console.log(err)
            this.performingRequest = false
            this.errorMsg = err.message
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    resetPassword () {
      try {
        if (this.passwordForm.email.length) {
          this.performingRequest = true
          fb.auth.sendPasswordResetEmail(this.passwordForm.email).then(() => {
            this.performingRequest = false
            this.passwordResetSuccess = true
            this.passwordForm.email = ''
          }).catch(err => {
            console.log(err)
            this.performingRequest = false
            this.errorMsg = err.message
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
