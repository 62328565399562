<template>
<div class="relative bg-white overflow-hidden">
<div class="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
      <svg class="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8" width="640" height="784" fill="none" viewBox="0 0 640 784">
        <defs>
          <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor" />
        <rect x="118" width="404" height="784" fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
      </svg>
    </div>
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
      <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
        <defs>
          <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
      </svg>
      <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
        <defs>
          <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
      </svg>
      <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
        <defs>
          <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
      </svg>
    </div>
  </div>
    <div class="relative pt-6 pb-16 sm:pb-24">
      <Popover>
        <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
          <div class="flex items-center flex-1">
            <div class="flex items-center justify-between w-full md:w-auto">
              <router-link to="/">
                <span class="sr-only">Loops</span>
                <img class="h-8 w-auto sm:h-10" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630871487/loops_icon_n9ebrj.svg" alt="Loops Logo" />
              </router-link>
              <div class="-mr-2 flex items-center md:hidden">
                <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Open main menu</span>
                  <MenuIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="hidden md:block md:ml-10 md:space-x-10">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="font-medium text-gray-500 hover:text-gray-900">{{ item.name }}</a>
            </div>
          </div>
          <div class="hidden md:block text-right">
            <span class="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
              <router-link v-if="!currentUser" to="/login" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                Log in
              </router-link>
              <router-link v-else to="/app" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">
                Go to Dashboard
              </router-link>
            </span>
          </div>
        </nav>
        <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img class="h-8 w-auto" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630871487/loops_icon_n9ebrj.svg" alt="" />
                </div>
                <div class="-mr-2">
                  <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span class="sr-only">Close main menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="px-2 pt-2 pb-3 space-y-1">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
              </div>
              <router-link v-if="!currentUser" to="/login" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">
                Log in
              </router-link>
              <router-link v-else to="/app" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">
                Go to Dashboard
              </router-link>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
</div>
  <div class="relative px-4 sm:px-6 lg:px-8 pb-16">
    <div class="text-lg max-w-prose prose prose-indigo mx-auto">
    <h1>
        <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Getting Started with Loops</span>
        <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Installing the Loops JS Widget</span>
    </h1>
    <p>Loops lets you continuously capture insights and feedback from customers and visitors so you can keep your finger on the pulse of what they need.</p>

    <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/df8c45c698cb4578af4a00562b717482?hide_owner=true&hide_speed=true&hide_title=true&hideEmbedTopBar=true" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
    <p>The most common way to install Loops on your website is through direct injection of your personalized Javascript snippet. Here's a step-by-step guide to get Loops up and running on your site and start continuously collecting customer feedback!</p>

    <p>In this doc we'll cover:</p>

    <ul role="list">
      <li><a href="#install">Installing Loops on your website</a></li>
      <li><a href="#customize">Customizing your feedback widget</a></li>
      <!-- <li>Sharing insights with your team</li> -->
      <li><a href="#faq">Frequently asked questions</a></li>
    </ul>

    <h2 id="install">Installing Loops on your website</h2>
    <p><strong>Locate the Code Snippet.</strong>You can find the snippet by clicking Install in the prompt on your dashboard or going to <strong>App > App Settings > Loops Widget</strong>.</p>
    <p><strong>Click Copy Code</strong>. That will copy the code to your clipboard.</p>

    <p>Paste the code just before the end of the &lt;/body> section of your website's html template.</p>
    <blockquote>Your Loops account has a unique code snippet that’s connected with your account. Make sure you use the code located in your account!</blockquote>
    <p>Logging in to Loops for the first time? As part of the setup process you’ll be prompted to install the widget code as soon as you land on your dashboard for the first time.</p>
    <pre class="text-sm">
  <code class="language-js">&lt;script>
    (function (w, d, s, o, f, js, fjs) {
        w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', '_hw', '{{widgetUrl}}'));
    _hw('init', { minimized: true, appId: '{ YOUR APP ID }' })
  &lt;/script&gt;</code></pre>

    <h2 id="customize">Customizing your feedback widget</h2>
    <p>The default feedback widget can be customized using our additional configuration options to fit your brand and copy preferences.</p>
<pre class="text-sm">
  <code class="language-js">{
      minimized: boolean;
      disableDarkMode: boolean;
      text: {
          buttonText?: string;
          formTitle?: string;
          formSubTitle?: string;
          thankYouTitle?: string;
          thankYouBody?: string;
      };
      styles: {
          classNameContainer?: string;
      };
  }</code></pre>
  <p>You can also control the widget by firing events from your own website, for example click <a href="javascript:;" onclick="_hw('event', 'open')" class="text-indigo-500 underline">here to open</a>, and here <a href="javascript:;" onclick="_hw('event', 'close')" class="text-indigo-500 underline">to close</a> the demo on this page.</p>

  <hr>

  <h2 id="faq">Frequently Asked Questions</h2>
  <h3>What happens after I install Loops?</h3>
  <p>Once the Loops code snippet is deployed on your website your visitors will begin to see a feedback button based on your configuration and you will start seeing feedback responses in your Loops dashboard. When new feedback is submitted we will also send an email to all account members.</p>

  <h3>Can you install Loops for me?</h3>
  <p>We can not install Loops on your website for you, as we don't have access to your site's code. If you are having any issues with installing Loops on your site, click the support link in your account or email help@shortloops.app.</p>

  <h3>Will Loops affect page load times?</h3>
  <p>No. Your site will load just as fast as it does today. Loops is loaded asynchronously. This means it won’t slow down your page or prevent users from interacting with your site. Loops also uses a Content Delivery Network (CDN) to ensure that Loops appears as efficiently as possible no matter where in the world your users are coming from.</p>
    </div>
</div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'

const navigation = [
  // { name: 'Product', href: '#' },
  // { name: 'Features', href: '#' },
  // { name: 'Marketplace', href: '#' },
  // { name: 'Company', href: '#' }
]

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon
  },
  setup () {
    return {
      navigation
    }
  },
  mounted () {
    window.analytics.page('Help')
    const widgetUrl = window.location.origin + '/widget.js'
    this.initWidget(window, document, 'script', '_hw', widgetUrl)
    window._hw('init', { minimized: true, appId: 'EGMRsAJ' })
  },
  computed: {
    ...mapState(['currentUser']),
    widgetUrl () {
      return window.location.origin + '/widget.js'
    }
  },
  methods: {
    initWidget (w, d, s, o, f, js, fjs) {
      w[o] = w[o] || function () {
        (w[o].q = w[o].q || []).push(arguments)
      }
      js = d.createElement(s)
      fjs = d.getElementsByTagName(s)[0]
      js.id = o
      js.src = f
      js.async = 1
      fjs.parentNode.insertBefore(js, fjs)
    }
  }
}
</script>
