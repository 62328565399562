<template>
  <div id="login" class="flex min-h-screen bg-white">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-lg min-w-md">
        <div>
          <img class="h-12 w-auto" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630871487/loops_icon_n9ebrj.svg" alt="Loops Logo">
          <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
            <span>Get started for free</span>
          </h2>
          <p class="mt-2 text-base text-gray-500 max-w">
            Try the widget for free with no time limit or credit card requirement. Upgrade to unlock unlimited feedback submissions if you are happy.
          </p>
        </div>

        <div class="mt-8">

          <div class="mt-6">

            <form @submit.prevent>
              <div class="mt-6">
                <label for="name" class="block text-sm font-medium leading-5 text-gray-700">
                  Name
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input v-model.trim="signupForm.name" id="name" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                </div>
              </div>

              <div class="mt-6">
                <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                  Email
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input v-model.trim="signupForm.email" id="email" type="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                </div>
              </div>

              <div class="mt-6">
                <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                  Password
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input v-model.trim="signupForm.password" id="password" type="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                </div>
              </div>

              <!-- <div class="mt-6">
                <label for="name" class="block text-sm font-medium leading-5 text-gray-700">
                  Company
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <input v-model.trim="signupForm.company" id="company" type="text" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                </div>
              </div> -->

              <div class="mt-6 flex items-center justify-between">
                <div class="flex items-center">
                  <input id="terms" v-model="signupForm.terms" type="checkbox" required class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                  <label for="terms" class="ml-2 block text-sm leading-5 text-gray-900">
                    I agree to the Short Loops <router-link to="/terms" target="_blank" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">Terms</router-link> and <router-link to="/privacy" target="_blank" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">Privacy Policy</router-link>.
                  </label>
                </div>
              </div>

              <transition name="fade">
                <div v-if="errorMsg !== ''" class="bg-red-50 border-l-4 border-red-400 p-4 mt-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
                      </svg>
                    </div>
                    <div class="ml-3">
                      <p class="text-sm leading-5 text-red-700">
                        {{ errorMsg }}
                      </p>
                    </div>
                  </div>
                </div>
              </transition>

              <div class="mt-6">
                <span class="block w-full rounded-md shadow-sm">
                  <button @click="signup" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                    <span v-if="!performingRequest">Get Started</span>
                    <transition name="fade">
                        <div v-if="performingRequest" class="loading">
                            <p>Creating account...</p>
                        </div>
                    </transition>
                  </button>
                </span>
              </div>

              <p class="mt-3 text-base text-gray-500 max-w">
                Already have an account?
                <span>
                  <router-link to="/login" class="underline hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    Login
                  </router-link>
                </span>
              </p>

            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="hidden lg:block relative w-0 flex-1 lg:pl-32 lg:pr-32 py-12 bg-gray-900">
      <div class="mx-auto max-w-md px-6 lg:mx-0 md:h-full flex flex-col justify-center space-y-6 opacity-75 hover:opacity-100 transition ease-in-out duration-300">
        <div class="flex flex-col px-0 py-8 w-full border-indigo-900">
          <div class="flex flex-grow">
            <div class="leading-relaxed flex flex-col">
              <blockquote class="text-white italic mt-1 relative">
                <div class="absolute pin-t pin-l -mt-2 -ml-2">
                  <svg class="w-8 h-8" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.264 19.552C15.264 23.2 17.664 25.12 20.352 25.12C23.328 25.12 26.112 22.624 26.112 19.456C26.112 16.864 24.288 15.136 22.08 15.136C21.888 15.136 21.408 15.136 21.312 15.136C22.368 12.064 25.824 8.8 29.376 7.072L26.4 4C20.448 6.976 15.264 13.504 15.264 19.552ZM0 19.552C0 23.2 2.304 25.12 5.088 25.12C8.064 25.12 10.848 22.624 10.848 19.456C10.848 16.864 8.928 15.136 6.72 15.136C6.528 15.136 6.048 15.136 5.952 15.136C7.008 12.064 10.56 8.8 14.016 7.072L11.136 4C5.184 6.976 0 13.504 0 19.552Z" class="fill-current text-indigo-600"></path>
                  </svg>
                </div>
                <p class="relative text-lg">
                  “Every company in the world will tell you they are customer-driven...But after spending 20 years in my career building and leading SaaS companies, I’ve learned that none of that means anything unless you actually make the structural decisions to ensure it.”
                </p>
              </blockquote>
              <div class="pt-6 mt-auto">
                <div class="flex items-start md:items-center justify-between">
                  <div class="flex items-start md:items-center">
                    <img class="w-12 h-12 rounded-full border-2 border-white shadow-lg" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630868067/david_cancel.jpg" loading="lazy" alt="David Cancel">
                    <div class="pl-4">
                      <div class="text-white uppercase font-semibold tracking-wider text-sm">David Cancel</div>
                      <div class="text-indigo-200 text-sm">CEO, Drift</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')
export default {
  data () {
    return {
      signupForm: {
        name: '',
        email: '',
        password: '',
        company: '',
        terms: false
      },
      performingRequest: false,
      errorMsg: ''
    }
  },
  created () {
    if (this.$route.query.name) {
      this.signupForm.name = this.$route.query.name
    }

    if (this.$route.query.email) {
      this.signupForm.email = this.$route.query.email
    }
  },
  mounted () {
    window.analytics.page('Signup')
    const widgetUrl = window.location.origin + '/widget.js'
    this.initWidget(window, document, 'script', '_hw', widgetUrl)
    window._hw('init', { minimized: true, appId: 'EGMRsAJ', text: { buttonText: 'Have Feedback?' } })
  },
  computed: {
    ...mapState(['userProfile', 'currentUser'])
  },
  methods: {
    createAppId (length) {
      const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

      const ID_LENGTH = length

      let appId = ''
      for (var i = 0; i < ID_LENGTH; i++) {
        appId += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
      }
      return appId
    },
    initWidget (w, d, s, o, f, js, fjs) {
      w[o] = w[o] || function () {
        (w[o].q = w[o].q || []).push(arguments)
      }
      js = d.createElement(s)
      fjs = d.getElementsByTagName(s)[0]
      js.id = o
      js.src = f
      js.async = 1
      fjs.parentNode.insertBefore(js, fjs)
    },
    signup () {
      try {
        if (this.signupForm.email.length && this.signupForm.password.length && this.signupForm.name && this.signupForm.terms) {
          this.performingRequest = true
          fb.auth.createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password).then(user => {
            this.$store.commit('setCurrentUser', user.user)

            // Get a new write batch
            const batch = fb.db.batch()

            // Create an appId
            const appId = this.createAppId(7)

            // Create a timestamp
            const timestamp = Date.now()

            // Identify user
            window.analytics.identify(user.user.uid, {
              name: this.signupForm.name,
              email: user.user.email
            })

            // Track Sign Up
            window.analytics.track('User Signed Up', {
              name: this.signupForm.name,
              email: user.user.email
            })

            window.analytics.track('Account Created', {
              account_id: appId
            })

            // Create a user record
            batch.set(fb.usersCollection.doc(user.user.uid), {
              name: this.signupForm.name,
              signed_terms: timestamp,
              accounts: [appId]
            })

            // Create an associated account
            batch.set(fb.accountsCollection.doc(appId), {
              name: this.signupForm.company,
              created: timestamp,
              appId: appId,
              billing_owner: user.user.email,
              activated: false
            })

            // Add the new user as a team member
            batch.set(fb.accountsCollection.doc(appId).collection('users').doc(user.user.uid), {
              email: user.user.email,
              role: 'owner',
              created: timestamp
            })

            batch.commit().then(() => {
              this.$store.dispatch('fetchUserProfile')
              this.performingRequest = false
              this.$router.push('/app')
            })
              .catch(err => {
                console.log(err)
                this.performingRequest = false
                this.errorMsg = err.message
              })
          }).catch(err => {
            console.log(err)
            this.performingRequest = false
            this.redirect = false
            this.errorMsg = err.message
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
