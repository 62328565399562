<template>
  <div id="account" class="h-screen overflow-hidden bg-gray-50">
    <div class="py-4 px-4 sm:px-6 lg:max-w-5xl lg:mx-auto lg:px-8">
      <TabGroup vertical>
        <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <TabList class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav class="space-y-1">
            <Tab v-slot="{ selected }" as="template" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <button :class="[selected ? 'bg-gray-50 text-orange-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']">
                <component :is="UserCircleIcon"  aria-hidden="true" :class="['text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" />
                <span class="truncate">Profile</span>
              </button>
            </Tab>
            <Tab v-slot="{ selected }" as="template" class="text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium">
              <button :class="[selected ? 'bg-gray-50 text-orange-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']">
              <component :is="CreditCardIcon" aria-hidden="true" :class="['text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" />
              <span class="truncate">Billing</span>
            </button>
            </Tab>
            <!-- <a v-for="item in subNavigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-50 text-orange-600 hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
              <component :is="item.icon" :class="[item.current ? 'text-orange-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
              <span class="truncate">
                {{ item.name }}
              </span>
            </a> -->
          </nav>
        </TabList>
        <!-- Be sure to use this with a layout container that is full-width on mobile -->
        <TabPanels class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-9">
        <TabPanel>
        <div class="bg-white overflow-hidden shadow sm:rounded-lg mb-8">
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h3>
              <p class="mt-1 text-sm leading-5 text-gray-500">
                Update personal information for your account.
              </p>
            </div>
          <div class="px-4 py-5 sm:p-6">
            <form @submit.prevent>
                <div class="">
                  <label for="name" class="block text-sm font-medium leading-5 text-gray-700">Name</label>
                  <div class="mt-1 relative rounded-md shadow-sm w-full md:w-4/6">
                      <input v-model.trim="name" type="text" :placeholder="userProfile.name" id="name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                  </div>
                </div>
                <button @click="updateProfile" class="mt-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">Update Profile</button>
            </form>
            <transition name="fade">
              <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mt-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: solid/check-circle -->
                    <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">
                      Successfully updated
                    </p>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="bg-white overflow-hidden shadow sm:rounded-lg mb-8">
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Account Security
              </h3>
              <p class="mt-1 text-sm leading-5 text-gray-500">
                Request an email to securely update your password.
              </p>
            </div>
          <div class="px-4 py-5 sm:p-6">
            <form @submit.prevent v-if="!passwordResetSuccess">
                <label for="name" class="block text-sm font-medium leading-5 text-gray-700">Email</label>
                <div class="mt-1 relative rounded-md shadow-sm w-full md:w-4/6">
                    <input type="text" :value="currentUser.email" id="name" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-400 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" disabled />
                </div>
                <button @click="resetPassword" class="mt-6 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">Reset Password</button>
            </form>
            <transition v-else name="fade">
                <div class="rounded-md bg-green-50 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm leading-5 font-medium text-green-800">
                          Email Sent
                        </h3>
                        <div class="mt-2 text-sm leading-5 text-green-700">
                          <p>
                            Check your email for a link to reset your password.
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
              </transition>
          </div>
        </div>
        </TabPanel>
        <TabPanel>
          <div class="bg-white overflow-hidden shadow sm:rounded-lg mb-8">
              <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Account Billing
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  View invoices, update subscription & payment methods.
                </p>
              </div>
              <div class="px-4 py-5 sm:p-6">
                <div class="flex items-center mb-4">
                  <h4 class="text-base font-medium text-gray-900 mr-4">Current Plan</h4>
                  <div class="bg-green-100 text-green-800 inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium capitalize">Free</div>
                </div>

                <div>
                  <label class="text-gray-400">{{ responses && responses.length ? responses.length : 0 }} / 25 feedback submissions</label>
                  <div class="w-full h-2 bg-gray-200 rounded-full mt-1 relative">
                    <div class="h-2 bg-green-200 absolute w-0 rounded-full" v-bind:style="responsePercentage"></div>
                  </div>
                </div>

              </div>
              <!-- <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button @click="accessBillingPortal" class="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                  <span v-if="!creatingBillingPortal">View Billing Settings</span>
                  <transition name="fade">
                      <div v-if="creatingBillingPortal" class="loading">
                          <p>Loading...</p>
                      </div>
                  </transition>
                </button>
              </div> -->
          </div>
        </TabPanel>
      </TabPanels>
    </div>
      </TabGroup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { loadStripe } from '@stripe/stripe-js'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import {
  CreditCardIcon,
  UserCircleIcon
} from '@heroicons/vue/outline'
const fb = require('../firebaseConfig.js')

// const subNavigation = [
//   { name: 'Profile', href: '#', icon: UserCircleIcon, current: false },
//   { name: 'Plan & Billing', href: '#', icon: CreditCardIcon, current: true }
// ]

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel
  },
  setup () {
    return {
      UserCircleIcon,
      CreditCardIcon
    }
  },
  data () {
    return {
      name: '',
      showSuccess: false,
      passwordResetSuccess: false,
      performingRequest: false,
      errorMsg: ''
      // creatingBillingPortal: false
    }
  },
  mounted () {
    window.analytics.page('Account Settings')
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'responses']),
    responsePercentage () {
      const percentage = this.responses ? ((this.responses.length / 25) * 100).toString() + '%' : '0%'
      const style = {
        width: percentage
      }
      return style
    }
  },
  methods: {
    // async createCheckout (uid) {
    //   // Init Stripe
    //   const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
    //   const docRef = await fb
    //     .usersCollection
    //     .doc(uid)
    //     .collection('checkout_sessions')
    //     .add({
    //       price: process.env.VUE_APP_STRIPE_TEST_PLAN,
    //       success_url: window.location.origin,
    //       cancel_url: window.location.origin
    //     })
    //     // Wait for the CheckoutSession to get attached by the extension
    //   docRef.onSnapshot((snap) => {
    //     const { error, sessionId } = snap.data()
    //     if (error) {
    //       // Show an error to your customer and then inspect your function logs.
    //       this.errorMsg = error.message
    //       this.performingRequest = false
    //     }
    //     if (sessionId) {
    //       // We have a session, let's redirect to Checkout
    //       stripe.redirectToCheckout({ sessionId })
    //     }
    //   })
    // },
    updateProfile () {
      this.$store.dispatch('updateProfile', {
        name: this.name !== '' ? this.name : this.userProfile.name
      })
      this.name = ''
      this.showSuccess = true
      setTimeout(() => { this.showSuccess = false }, 2000)
    },
    resetPassword () {
      this.performingRequest = true
      fb.auth.sendPasswordResetEmail(this.currentUser.email).then(() => {
        this.performingRequest = false
        this.passwordResetSuccess = true
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.errorMsg = err.message
      })
    }
    // async accessBillingPortal () {
    //   this.creatingBillingPortal = true
    //   try {
    //     // Call billing portal function
    //     const functionRef = fb
    //       .functions
    //       .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
    //     const { data } = await functionRef({ returnUrl: window.location.origin })
    //     window.location.assign(data.url)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  }
}
</script>
