<template>
  <div class="relative pb-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="relative pt-6 pb-16 sm:pb-24">
      <Popover>
        <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
          <div class="flex items-center flex-1">
            <div class="flex items-center justify-between w-full md:w-auto">
              <router-link to="/">
                <span class="sr-only">Loops</span>
                <img class="h-8 w-auto sm:h-10" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630871487/loops_icon_n9ebrj.svg" alt="Loops Logo" />
              </router-link>
              <div class="-mr-2 flex items-center md:hidden">
                <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span class="sr-only">Open main menu</span>
                  <MenuIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="hidden md:block md:ml-10 md:space-x-10">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="font-medium text-gray-500 hover:text-gray-900">{{ item.name }}</a>
            </div>
          </div>
          <div class="hidden md:block text-right">
            <span class="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
              <router-link v-if="!currentUser" to="/login" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
                Log in
              </router-link>
              <router-link v-else to="/app" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">
                Go to Dashboard
              </router-link>
            </span>
          </div>
        </nav>
        <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img class="h-8 w-auto" src="https://res.cloudinary.com/dfg85dhw8/image/upload/v1630871487/loops_icon_n9ebrj.svg" alt="" />
                </div>
                <div class="-mr-2">
                  <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span class="sr-only">Close main menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="px-2 pt-2 pb-3 space-y-1">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
              </div>
              <router-link v-if="!currentUser" to="/login" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">
                Log in
              </router-link>
              <router-link v-else to="/app" class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100">
                Go to Dashboard
              </router-link>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
</div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Legal</span>
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Terms of Service</span>
        </h1>
        <p class="mt-8 text-xl text-gray-500 leading-8">The following terms and conditions govern all use of the Loops website and all content, products and services products available at or through the website (taken together, the “Website”). It also includes the customer research service (including capturing, analyzing and reporting on customer insights) provided to you (“the Service”).</p>

        <p class="mt-8 text-xl text-gray-500 leading-8">The Website is owned and operated by Balsm Labs LLC dba. Loops ("Loops"). The Website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Loops Privacy Policy and procedures that may be published from time to time on this Website by Loops (collectively, the "Agreement").</p>

        <p class="mt-8 text-xl text-gray-500 leading-8">Please read this Agreement carefully before accessing or using the Website. By accessing or using any part of the web site, you agree to become bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the Website or use any services. If these terms and conditions are considered an offer by Loops, acceptance is expressly limited to these terms.</p>

        <p class="mt-8 text-xl text-gray-500 leading-8">This agreement also covers use of your Account, created when you sign up to the service. The Website is available only to individuals who are at least 13 years old.</p>
      </div>
      <div class="mt-12 text-lg text-gray-500 prose prose-indigo max-w-prose mx-auto">
        <h2>Payment and Renewal</h2>
        <h4>Free Trial</h4>
        <ul role="list">
          <li>the end of the free trial period for which you registered or are registering to use the</li>
          <li>applicable Service or the start date of a Purchased Service ordered by you.</li>
        </ul>
        <p>Additional trial terms and conditions may appear on the trial registration web page. Any such additional terms and conditions are incorporated into this Agreement by reference and are legally binding.</p>

        <h4>Payment of fees for a purchased service</h4>
        <p>By signing up for a paid Loops plan you agree to pay Loops the specified monthly or annual fees in exchange for use of the service (the “Purchased Service”) for the period indicated.</p>
        <p>Applicable fees will be charged starting from the day your access is established and in advance of using such services. Loops reserves the right to change the payment terms and fees upon seven (7) days prior written notice to you. Access can be canceled by you at anytime on written notice to Loops.</p>
        <p>Applicable fees will be clearly displayed to you on the payment page and/or clearly communicated via email.</p>

        <h4>Automatic Renewal</h4>
        <p>Unless you notify Loops before the end of the applicable subscription period that you want to cancel, your subscription will automatically renew and you authorize us to collect the then-applicable annual or monthly subscription fee for the Service (as well as any taxes) using any credit card or other payment mechanism we have on record for you.</p>

        <h4>Automatic Plan Selection</h4>
        <p>Automatic Plan Selection means you allow Loops to move you to a subscription plan (and associated fee) that is appropriate to your usage of the Service in the previous month. Automatic Plan Selection is optional.</p>

        <p>If you decide not to use Automated Plan Selection and Loops receives more customer feedback responses than that of your plan you must top up your account. If the top up payment is not received within 3 days your service will paused until the start of your next billing period.</p>

        <h4>Cancellation</h4>
        <p>The Service can be canceled at any time by contacting Loops via the Website or email.</p>

        <h2>Use of your Account and the Service</h2>
        <h4>Our Responsibilities</h4>
        <p>We shall:</p>
        <ul role="list">
          <li>provide basic support for the Purchased Service to You, and</li>
          <li>use commercially reasonable efforts to make the Purchased Service available, except for: 1. planned downtime, or 2. any unavailability caused by circumstances beyond Loops reasonable control, including without limitation, acts of God, acts of government, floods, fires, earthquakes, civil unrest, acts of terror, labour strikes, Internet service provider failures or delays, or denial of service attacks</li>
          <li>provide the Purchased Service only in accordance with applicable laws and government regulations.</li>
        </ul>

        <h4>Our Protection of Your Data</h4>
        <p>We shall maintain appropriate administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of Your Data.</p>
        <p>We shall not disclose Your Data except as compelled by law in accordance or as expressly permitted in writing by You.</p>

        <h4>Your Responsibilities (things you must do)</h4>
        <p>You shall:</p>
        <ul role="list">
          <li>be responsible for compliance with this Agreement,</li>
          <li>use commercially reasonable efforts to prevent unauthorized access to or use of your Account, and notify us promptly of any such unauthorized access or use,</li>
          <li>be fully responsible for all activities that occur under the account,</li>
          <li>use the Website in strict accordance with the Loops <router-link to="/privacy">Privacy Policy</router-link>, use the Services only in accordance with applicable laws and government regulations.</li>
        </ul>

        <h4>Your Responsibilities (things you must not do)</h4>
        <p>You shall not:</p>

        <ul role="list">
          <li>make the Services available to anyone other than yourself,</li>
          <li>sell, resell, rent or lease the Services,</li>
          <li>use the Services to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party copyright, trademark, privacy or other proprietary rights,</li>
          <li>use the Services to store or transmit viruses, worms, trojan horses, and other harmful or destructive content (“Malicious Code”),</li>
          <li>interfere with or disrupt the integrity or performance of the Services or third-party data contained therein, or</li>
          <li>attempt to gain unauthorized access to the Services or related systems or networks.</li>
          <li>during the term of this Agreement and for a period of six months after it ends, you must not directly or indirectly solicit for employment or contract for service any employee or representative of Loops. This does not preclude you from advertising or recruiting generally in the media.</li>
        </ul>

        <h4>Usage Limitations</h4>
        <p>Services may be subject to other limitations, such as, for example, limits on the number of customer insights analyzed and reported on in any given period or the timeliness of such responses. Any such limitations are specified in your Account (on the Website) or clearly communicated to you via email.</p>

        <h2>Communications from us</h2>
        <p>The Service may include certain communications from Loops, such as service announcements, administrative messages and newsletters. You understand that these communications shall be considered part of using the Services. You have the option of opting out from receiving optional communications such as newsletters. However, you will not be able to opt-out from receiving service announcements and administrative messages.</p>

        <h4>Responsibility of Website Visitors and users of the Service</h4>
        <p>In operating the Website and the Service, Loops may receive and pass along to you content (“the material”) that contains links to third party web sites, is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. The material may also contain computer software, including viruses and other harmful material.</p>
        <p>You (and your users) are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, trojan horses, and other harmful or destructive content,</p>
        <p>The Website and Service may also handle material that violates the privacy or publicity rights, or infringes the copyright, trademark and other proprietary rights of third parties, or the downloading, copying or use of which is subject to additional terms and conditions, stated or unstated.</p>
        <p>Loops disclaims any responsibility for any harm resulting from the use by visitors of the Website or users of the Service.</p>

        <h2>Inactive User Accounts Policy</h2>
        <p>We reserve the right to terminate unpaid user accounts that are inactive for a continuous period of 180 days. In the event of such termination, all data associated with such user account may be deleted. We will provide you prior notice of such termination in advance by email.</p>

        <h2>Copyright, Trademark and other Proprietary rights</h2>
        <p>This Agreement does not transfer from Loops to you any Loops or third party copyright or trademark rights, and all rights, title and interest in and to such property will remain (as between the parties) solely with Loops., Loops, the Loops logo, and all other trademarks, service marks, graphics and logos used in connection with Loops or the Website are trademarks or registered trademarks of Loops or Loops licensors. Other trademarks, service marks, graphics and logos used in connection with the Website may be the trademarks of other third parties. Your use of the Website grants you no right or license to reproduce or otherwise use any Loops or third-party trademarks.</p>

        <h2>Changes</h2>
        <p>Loops reserves the right, at its sole discretion, to modify or replace any part of this Agreement. It is your responsibility to check this Agreement periodically for changes. Your continued use of or access to the Website following the posting of any changes to this Agreement constitutes acceptance of those changes.</p>
        <p>Loops may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall be subject to the terms and conditions of this Agreement.</p>

        <h2>Termination</h2>
        <p>Loops may terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately.</p>
        <p>If you wish to terminate this Agreement or your Loops account, you may do so from your account settings in the Loops app.</p>
        <p>All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

        <h2>Disclaimer of Warranties</h2>
        <p>The Website and Service are provided “as is”. Loops and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement.</p>
        <p>Neither Loops nor its suppliers and licensors makes any warranty that the Website will be error free or that access there to will be continuous or uninterrupted.</p>
        <p>You understand that you obtain services through the Website at your own discretion and risk.</p>

        <h2>Limitation of Liability</h2>
        <p>In no event will Loops, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for:</p>
        <ul role="list">
          <li>any special, incidental or consequential damages;</li>
          <li>the cost of procurement for substitute products or services;</li>
          <li>for interruption of use or loss or corruption of data; or</li>
          <li>for any amounts that exceed the fees paid by you to Loops under this agreement during the twelve (12) month period prior to the cause of action.</li>
        </ul>

        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold harmless Loops, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including lawyers’ fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.</p>

        <h2>Miscellaneous</h2>
        <p>This Agreement constitutes the entire agreement between Loops and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of Loops, or by the posting by Loops of a revised version.</p>
        <p>Except to the extent applicable law, if any, provides otherwise, this Agreement, any access to or use of the Website will be governed by the laws of the state of New Jersey, U.S.A., excluding its conflict of law provisions, and the proper venue for any disputes arising out of or relating to any of the same will be the state and federal courts located in Camden County, New Jersey.</p>
        <p>If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties’ original intent, and the remaining portions will remain in full force and effect.</p>
        <p>A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.</p>
        <p>You may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; Loops may assign its rights under this Agreement without condition.</p>
        <p>This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.</p>

        <p>This policy is effective as of September 8th, 2021.</p>

      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'

const navigation = [
  // { name: 'Product', href: '#' },
  // { name: 'Features', href: '#' },
  // { name: 'Marketplace', href: '#' },
  // { name: 'Company', href: '#' }
]

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon
  },
  setup () {
    return {
      navigation
    }
  },
  mounted () {
    window.analytics.page('Terms')
  },
  computed: {
    ...mapState(['currentUser'])
  }
}
</script>
